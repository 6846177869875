<script>
import ConfirmationModal from './ConfirmationModal.vue'
export default {
  components: { ConfirmationModal },
  name: 'ListItem',
  props: {
    text: {
      type: String
    },
    description: {
      type: String
    },
    modalConfirmTitle: {
      type: String
    },
    modalConfirmDescription: {
      type: String
    },
    viewBtn: {
      type: Boolean,
      default: false
    },
    pencilBtn: {
      type: Boolean,
      default: false
    },
    deleteable: {
      type: Boolean,
      default: false
    },
    item: {
      type: String
    },
    reactivate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showConfirmationModal: false
    }
  },
  methods: {
    validateAction () {
      this.showConfirmationModal = false
      if (this.deleteable) {
        this.$emit('deleteItem', this.item)
      } else if (this.reactivate) {
        this.$emit('reactivateItem', this.item)
      }
    }
  }
}
</script>
<template>
  <div class="list-item--wrapper">
    <div class="list-item--left">
      <div class="list-item--info">
        <div class="list-item--upper-info-wrapper">
          <p>{{ text }}</p>
        </div>
        <span class="list-item--description" v-if="description">{{ description }}</span>
      </div>
    </div>
    <div class="list-item--right">
      <v-btn class="btn" color="#fff" @click="$emit('view')" v-if="viewBtn"><v-icon size="18" :color="getPrimaryColor">mdi-eye</v-icon></v-btn>
      <v-btn class="btn" color="#fff" @click="$emit('edit')" v-if="pencilBtn"><v-icon size="18" :color="getPrimaryColor">mdi-pencil</v-icon></v-btn>
      <v-btn class="btn ml-4" color="#fff" @click="showConfirmationModal = true" v-if="deleteable"><v-icon size="18" :color="getPrimaryColor">mdi-delete</v-icon></v-btn>
      <v-btn class="btn ml-4" color="#fff" @click="showConfirmationModal = true" v-if="reactivate"><v-icon size="18" :color="getPrimaryColor">mdi-replay</v-icon></v-btn>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="modalConfirmTitle"
      :description="modalConfirmDescription"
      :confirmText="$t('global:confirm')"
      :btnConfirmText="false"
      @confirm="validateAction()"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
    <!-- <confirmation-modal
      :show="showConfirmationModal"
      v-if="reactivate"
      :title="modalConfirmTitle"
      :description="modalConfirmDescription"
      :confirmText="$t('global:confirm')"
      :btnConfirmText="false"
      @confirm="deleteDepartment(department)"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    /> -->
  </div>
</template>
<style lang="scss">
.list-item--wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 20px;
  .list-item--left {
    display: flex;
    align-items: center;
    height: 40px;
    .list-item--info {
      text-align: left;
      .list-item--upper-info-wrapper {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #212121;
        }
      }
      span.list-item--description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #A0A0A0;
      }
    }
  }
  .list-item--right {
    display: flex;
    align-items: center;
    .v-btn {
      height: 30px;
      max-height: 30px;
      width: 30px;
      min-width: unset;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
